@import '../node_modules/tippy.js/dist/tippy.css';
@import '../node_modules/@splidejs/splide/dist/css/splide-core.min.css';

:root {
  --color--primary: #FFEB33;
  --color--secondary: #0028d8;
  --color--secondary-lighter: #4C69E4;
  --color--success: #00B674;
  --color--warning: #ff5722;
  --color--danger: #E22E2E;
  --color--neutral-0: #000000;
  --color--neutral-1: #222222;
  --color--neutral-3: #464646;
  --color--neutral-5: #999999;
  --color--neutral-6: #c4c4c4;
  --color--neutral-7: #d8d8d8;
  --color--neutral-8: #e5e5e5;
  --color--neutral-9: #fefefe;
  --color--neutral-10: #FFFFFF;
  --dimensions--borderRadius--small: .5rem;
  --dimensions--borderRadius--medium: 1rem;
  --dimensions--borderRadius--large: 2rem;
  --dimensions--spacing--small: clamp(.25rem, .25vw, .5rem);
  --dimensions--spacing--medium: clamp(1rem, 1vw, 1.5rem);
  --dimensions--spacing--large: clamp(2rem, 2vw, 3rem);
  --motion--duration--short: 150ms;
  --motion--duration--medium: 300ms;
  --motion--duration--long: 500ms;
  --motion--easing--inOut: ease-in-out;


  // App
  --ata--app--backgroundColor: var(--color--neutral-9);
  --ata--app--foregroundColor: var(--color--neutral-0);
  --ata--app--foregroundColor-light: var(--color--neutral-5);
  --ata--app--link--foregroundColor: var(--color--secondary);
  --ata--app--link--foregroundColor--hover: var(--color--secondary-lighter);
  --ata--app--divider--color: var(--color--neutral-7);
  --ata--app--bar--backgroundColor: var(--color--primary);
  --ata--app--bar--foregroundColor: var(--color--neutral-0);
  --ata--app--bar--blockSize: 7rem;
  --ata--app--navigation--inlineSize: 44rem;
  --ata--app--navigation--backgroundColor: #e3f6f8;
  --ata--app--navigation--foregroundColor: #000;
  --ata--app--navigation--backgroundColor--active: #bde2e5;
  --ata--app--navigation--foregroundColor--active: #000;
  --ata--app--infoBlock--backgroundColor: var(--color--neutral-8);
  --ata--app--infoBlock--foregroundColor: var(--color--neutral-0);

  // Layout
  --ata--layout--gap--small: var(--dimensions--spacing--small);
  --ata--layout--gap--medium: var(--dimensions--spacing--medium);
  --ata--layout--gap--large: var(--dimensions--spacing--large);

  // Forms
  --ata--form--error--foregroundColor: var(--color--danger);
  --ata--form--section--borderColor: var(--color--neutral-7);
  --ata--form--section--gap: var(--dimensions--spacing--large);
  --ata--form--field--gap: var(--dimensions--spacing--medium);

  // Cards
  --ata--card--backgroundColor: var(--color--neutral-10);
  --ata--card--borderRadius: var(--dimensions--borderRadius--medium);
  --ata--card--borderColor: var(--color--neutral-7);
  --ata--card--boxShadow: none;

  // Tables
  --ata--table--cell--backgroundColor: var(--color--neutral-10);
  --ata--table--cell--foregroundColor: var(--color--neutral-1);
  --ata--table--cell--padding: var(--dimensions--spacing--medium);
  --ata--table--cell--borderColor: var(--color--neutral-7);
  --ata--table--cell--backgroundColor--alternate: var(--color--neutral-9);
  --ata--table--cell--foregroundColor--alternate: var(--color--neutral-1);
  --ata--table--header--backgroundColor: var(--color--neutral-8);
  --ata--table--header--foregroundColor: var(--ata--table--cell--foregroundColor);
  --ata--table--header--borderColor: var(--ata--table--header--backgroundColor);
  --ata--table--header--offset: 7rem auto auto auto;
  --ata--table--rowGap: var(--dimensions--spacing--small);

  // Buttons
  --ata--button--borderRadius: var(--dimensions--borderRadius--small);
  --ata--button--blockSize: 5rem;
  --ata--button--blockSize--dense: 4rem;
  --ata--button--outlineColor: var(--color--neutral-7);
  --ata--button--color: var(--color--neutral-0);
  --ata--button--onColor: var(--color--neutral-10);
  --ata--button--color--disabled: var(--color--neutral-6);
  --ata--button--onColor--disabled: var(--color--neutral-5);
  --ata--button--color--primary: var(--color--secondary);
  --ata--button--onColor--primary: var(--color--neutral-10);
  --ata--button--color--secondary: var(--color--neutral-0);
  --ata--button--onColor--secondary: var(--color--neutral-10);
  --ata--button--color--success: var(--color--success);
  --ata--button--onColor--success: var(--color--neutral-0);
  --ata--button--color--warning: var(--color--warning);
  --ata--button--onColor--warning: var(--color--neutral-10);
  --ata--button--color--danger: var(--color--danger);
  --ata--button--onColor--danger: var(--color--neutral-10);

  // Inputs
  --ata--input--blockSize: 4rem;
  --ata--input--backgroundColor: var(--color--neutral-10);
  --ata--input--foregroundColor: var(--color--neutral-0);
  --ata--input--borderColor: var(--color--neutral-7);
  --ata--input--borderRadius: var(--dimensions--borderRadius--small);

  --ata--selection--blockSize: var(--ata--input--blockSize);
  --ata--selection--backgroundColor: var(--ata--input--backgroundColor);
  --ata--selection--foregroundColor: var(--ata--input--foregroundColor);
  --ata--selection--borderColor: var(--ata--input--borderColor);
  --ata--selection--borderRadius: var(--ata--input--borderRadius);

  // ProgressBar
  --ata--progressBar--blockSize: 2rem;

  // VehiclePageHeader
  --ata--vehiclePageHeader--backgroundColor: var(--color--neutral-8);
  --ata--vehiclePageHeader--borderColor: var(--color--neutral-7);

  // Charts
  --ata--chart--tooltip--backgroundColor: var(--color--neutral-1);
  --ata--chart--tooltip--foregroundColor: var(--color--neutral-10);
  --ata--chart--tooltip--dividerColor: var(--color--neutral-8);
  --ata--chart--tooltip--padding: var(--dimensions--spacing--medium);
  --ata--chart--tooltip--spacing: var(--dimensions--spacing--small);
  --ata--chart--tooltip--borderRadius: var(--dimensions--borderRadius--small);
  
  // Tooltip
  --ata--tooltip--backgroundColor: var(--color--neutral-1);
  --ata--tooltip--foregroundColor: var(--color--neutral-10);
  --ata--tooltip--borderRadius: var(--dimensions--borderRadius--small);
  --ata--tooltip--padding: var(--dimensions--spacing--small);

  // Vehicle
  --ata--vehicle--image--background: var(--color--neutral-7);
}

/**** View Transitions ****/

@media (prefers-reduced-motion) {
  ::view-transition-group(*),
  ::view-transition-old(*),
  ::view-transition-new(*) {
    animation: none !important;
  }
}

::view-transition-old(*),
::view-transition-new(*) {
  animation: none;
  mix-blend-mode: normal;
}

/**** Global ****/

* {
  //margin: 0;
  //padding: 0;
  box-sizing: border-box;
  //font-size: 1em;
  accent-color: var(--color--secondary);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

html {
  font-size: 8px;
  font-family: Lato, sans-serif;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 14px;
  background: var(--ata--app--backgroundColor);
  color: var(--ata--app--foregroundColor);
  container-type: inline-size;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

hr {
  border: 0;
  border-top: 1px solid var(--ata--app--divider--color);
  inline-size: 100%;
  margin: 0;
}

a[href] {
  color: var(--ata--app--link--foregroundColor);
  text-decoration: none;

  &:hover, &:focus {
    color: var(--ata--app--link--foregroundColor--hover);
    text-decoration: underline;
  }
}

details > summary {
  list-style: none;
  cursor: pointer;
}

details[open] > summary {
  margin-block-end: var(--dimensions--spacing--medium);
}

details > summary::after {
  content: "\25BC";
  display: inline-block;
}

details[open] > summary::after {
  content: "\25B2";
}

.google-visualization-tooltip {
  border: none !important;
  background: none !important;
  border-radius: 0 !important;
  font-size: .85em !important;
}

.gm-style  {
  .gm-style-iw-c {
    background: var(--ata--tooltip--backgroundColor);
    color: var(--ata--tooltip--foregroundColor);
    border-radius: var(--ata--tooltip--borderRadius);
    padding: var(--ata--tooltip--padding);
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style-iw-tc::after {
    background: var(--ata--tooltip--backgroundColor);
  }

  .gm-ui-hover-effect>span {
    background-color: var(--ata--tooltip--foregroundColor);
  }
}

/**** Forms ****/

.form {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 3rem 2rem;
  align-items: center;

  .form__field {
    display: contents;

    .field__label {
      grid-column: 1;
    }

    .field__input {
      grid-column: 2;
    }
  }

  .form__full {
    grid-column: 1 / -1;
  }

  &[data-error="true"] {
    animation: shake .3s forwards ease-in-out;
  }

  .form__error {
    color: var(--ata--form--error--foregroundColor);
    font-size: .875em;
    line-height: 1.4em;

    &:empty {
      display: none;
    }
  }
}

.field:has(:user-invalid) {
  .field__label {
    color: var(--color--danger);
  }
}

.input {
  height: 5rem;

  background: var(--ata--input--backgroundColor);
  color: var(--ata--input--foregroundColor);
  line-height: 2.15em;
  font-size: 1em;
  font-weight: 400;

  border: 1px solid var(--ata--input--borderColor);
  border-radius: var(--ata--input--borderRadius);

  padding-inline: 1.5rem;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-.5rem);
    }

    50% {
        transform: translateX(.5rem);
    }

    75% {
        transform: translateX(-.5rem);
    }

    100% {
        transform: translateX(0);
    }
}

/**** Cards ****/

.card {
  outline: 1px solid var(--ata--card--borderColor);
  border-radius: var(--ata--card--borderRadius);
  overflow: hidden;

  .card__section:not(:last-child) {
    border-block-end: 1px solid var(--ata--card--borderColor);
  }
}


/**** Tables ****/

.table {
  display: table;
  width: 100%;
  max-width: 100%;

  border-collapse: separate;
  border-spacing: 0 .5rem;
}

.table__row {
  display: table-row;
  width: 100%;
  background: var(--ata--table--cell--backgroundColor);
  color: var(--ata--table--cell--foregroundColor);

  margin-bottom: 1rem;

  & > * {
    display: table-cell;
    box-sizing: border-box;
    padding: var(--ata--table--cell--padding);

    border: 1px solid var(--ata--table--cell--borderColor);
    border-right: none;

    max-width: 100%;

    &:last-child {
      border-right: 1px solid var(--ata--table--cell--borderColor);
    }
  }
}

.table__row--header {
  background-color: var(--ata--table--header--backgroundColor);
  color: var(--ata--table--header--foregroundColor);

  & > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    text-align: start;

    border-color: var(--ata--table--header--borderColor);

    font-weight: 600;
    vertical-align: middle;

    span.sorted {
      font-size: 0.8em;

      &::before {
        content: "\25BC";
        display: inline-block;
      }

      &.sorted--reverse::before {
        content: "\25B2";
      }
    }
  }
}


//@media (max-width: 1200px) {
//  .table {
//    display: flex;
//    flex-direction: column;
//  }
//
//  .table__row {
//    display: flex;
//    flex-direction: column;
//  }
//
//  .table__row--header {
//    display: none;
//  }
//}


/**** Buttons ****/

ata-text-button, ata-stroked-button, ata-filled-button {
  &[theme="primary"] {
    --ata--button--color: var(--ata--button--color--primary);
    --ata--button--onColor: var(--ata--button--onColor--primary);
    --ata--button--borderColor: var(--ata--button--color--primary);
  }

  &[theme="secondary"] {
    --ata--button--color: var(--ata--button--color--secondary);
    --ata--button--onColor: var(--ata--button--onColor--secondary);
    --ata--button--borderColor: var(--color--neutral-6);
  }

  &[theme="success"] {
    --ata--button--color: var(--ata--button--color--success);
    --ata--button--onColor: var(--ata--button--onColor--success);
    --ata--button--borderColor: var(--ata--button--color--success);
  }

  &[theme="warning"] {
    --ata--button--color: var(--ata--button--color--warning);
    --ata--button--onColor: var(--ata--button--onColor--warning);
    --ata--button--borderColor: var(--ata--button--color--warning);
  }

  &[theme="danger"] {
    --ata--button--color: var(--ata--button--color--danger);
    --ata--button--onColor: var(--ata--button--onColor--danger);
    --ata--button--borderColor: var(--ata--button--color--danger);
  }

  &:disabled {
    --ata--button--color: var(--ata--button--color--disabled);
    --ata--button--onColor: var(--ata--button--onColor--disabled);
    --ata--button--borderColor: var(--color--neutral-6);
  }
}

/**** AtaProgressBar ****/
ata-progress-bar {
  &[theme='Default'] {
    --ata--progressBar--backgroundColor: var(--color--neutral-7);
    --ata--progressBar--valueColor: var(--color--secondary);
  }

    &[theme='Success'] {
      --ata--progressBar--backgroundColor: var(--color--neutral-7);
      --ata--progressBar--valueColor: var(--color--success);
    }

    &[theme='Warning'] {
      --ata--progressBar--backgroundColor: var(--color--neutral-7);
      --ata--progressBar--valueColor: var(--color--warning);
    }

    &[theme='Danger'] {
      --ata--progressBar--backgroundColor: var(--color--neutral-7);
      --ata--progressBar--valueColor: var(--color--danger);
    }
}

/**** List ****/
.paragraph-list {
  margin: 0;
  padding: 0;
  list-style: none;

  & > * {
    display: inline;
  }

  &.paragraph-list--bullets > *:not(:last-child)::after {
    content: " \2022 ";
    margin-inline: var(--dimensions--spacing--small);
    color: initial;
  }
}

/**** Contained ****/

.contained {
  margin-inline: auto;
}

.contained--small {
  max-inline-size: 60rem;
}

.contained--medium {
  max-inline-size: 90rem;
}

.contained--large {
  max-inline-size: 120rem;
}


/**** Info Block ****/

.infoBlock {
  background: var(--ata--app--infoBlock--backgroundColor);
  color: var(--ata--app--infoBlock--foregroundColor);
  fill: var(--ata--app--infoBlock--foregroundColor);
  padding: var(--dimensions--spacing--large);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--dimensions--spacing--large);

  &[hidden] {
    display: none;
  }

  & > * {
    flex: 1 1 80ch;
  }


  h1 {
    font-size: 1.5em;
    line-height: 2em;
    font-weight: 700;
  }

  h2 {
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: 700;
    margin-block-start: 1em;
  }

  section {
    margin-block-end: var(--dimensions--spacing--medium);
  }

  p, ul, ol, dl {
    margin-block: 1rem 2rem;
    line-height: 1.6em;
  }

  ul, ol {
    margin-left: 0;
    padding-left: 2rem;
  }

  dl dt {
    margin-top: 2rem;
    font-weight: bold;
  }

  dl dd {
    margin-left: 0;
  }
}

/**** Ata Input ****/

ata-input {
  display: contents;
}

ata-input > span {
  display: none;
}

/**** Ata App Bar ****/

ata-app-bar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: ". logo action";
  align-items: center;
  height: 7rem;
  padding-inline: 1rem;

  background: var(--ata--app--bar--backgroundColor);
  color: var(--ata--app--bar--foregroundColor);
  fill: var(--ata--app--bar--foregroundColor);

  & > * {
    max-height: 100%;
    height: 3rem;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .appBar__logo {
    grid-area: logo;
  }

  .appBar__action {
    grid-area: action;
    justify-self: end;
  }

  @media (min-width: 1200px) {
    grid-template-columns: auto 1fr;
    grid-template-areas: "logo action";
  }
}


/**** Ata App Footer ****/

ata-app-footer {
  display: block;
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid var(--ata--app--divider--color);
  padding: 3rem 2rem;
  color: var(--color--neutral-5);
  font-size: .8em;
}

ata-app-footer > *:not(:last-child)::after {
  display: inline-block;
  content: '|';
  margin-inline: 1rem;
}


/**** Ata Page Header ****/
ata-page-header {
  border-block-end: 1px solid var(--ata--app--divider--color);
}


/**** Debounce Input ****/
debounce-input {
  display: contents;
}


/**** Custom ****/

main {
  container-name: main;
  container-type: inline-size;
}

/**** Shell ****/

.shell {
  transition: transform var(--motion--duration--long) var(--motion--easing--inOut);

  .shell__header {
    position: sticky;
    top: 0;
    z-index: 1;
    block-size: var(--ata--app--bar--blockSize);
  }

  .shell__navigation {
    position: fixed;
    left: 100%;
    top: 0;
    bottom: 0;
  }

  &:has(.shell__navigation[open]) {
    // Note: Error is invalid
    transform: translate(calc(var(--ata--app--navigation--inlineSize, 0rem) * -1));
  }

  @media (min-width: 1200px) {
    &:has(.shell__navigation) .shell__content {
      padding-left: var(--ata--app--navigation--inlineSize);
    }

    .shell__navigation {
      top: var(--ata--app--bar--blockSize);
      left: 0;
    }

    &:has(.shell__navigation[open]) {
      transform: translate(0);
    }
  }

  nav {
    width: var(--ata--app--navigation--inlineSize);
    background: var(--ata--app--navigation--backgroundColor);
    color: var(--ata--app--navigation--foregroundColor);
    fill: var(--ata--app--navigation--foregroundColor);
    height: 100%;

    ul {
      list-style: none;
    }

    & > ul > li {
      padding: 1rem 2rem;
      border-block-end: 1px solid var(--ata--app--divider--color);

      ul {
        list-style: none;
      }

      ul li {
        padding: 1rem 0;
      }

      ul li:first-child {
        padding-top: 2rem;
      }
    }

    details > summary {
      list-style: none;
    }
  }
}

.navigation :is(a, a:hover, a:focus) {
  color: currentColor;
  text-decoration: none;
}

.navigation__item,
.navigation__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  padding-block: 1rem;

  .navigation__icon {
    display: inline-flex;
    block-size: 2rem;
    aspect-ratio: 1;
  }

  .navigation__label {
  }
}

.navigation__item .navigation__label {
    text-transform: uppercase;
}

.navigation__header {
  padding: 3rem 2rem;
  gap: 2rem;

  .navigation__icon {
    block-size: 3rem;
  }
}

.navigation__sub-item {
  padding-inline-start: 5rem;
}


/**** Typography ****/

.typography--heading1 {
  font: 1.7em/1.4em normal Lato, sans-serif;
}

.typography--heading2 {
  font: 1.3em/1.4em normal Lato, sans-serif;
}

.typography--heading3 {
  font: 0.85em/1.4em bold Lato, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.typography--caption {
  font: .85em/1em normal Lato, sans-serif;
}

.color--neutral {
  color: var(--color--neutral-0);
  fill: var(--color--neutral-0);
}

.color--neutral--light {
  color: var(--color--neutral-5);
  fill: var(--color--neutral-5);
}

.color--success {
  color: var(--color--success);
  fill: var(--color--success);
}

.color--warning {
  color: var(--color--warning);
  fill: var(--color--warning);
}

.color--danger {
  color: var(--color--danger);
  fill: var(--color--danger);
}

/**** Layout ****/

.layout--horizontal,
.layout--horizontalWrap {
  display: flex;
  flex-direction: row;

  &.align--start--start { align-items: start; justify-content: start; }
  &.align--start--center { align-items: center; justify-content: start; }
  &.align--start--end { align-items: end; justify-content: start; }
  &.align--start--fill { align-items: stretch; justify-content: start; }
  &.align--end--start { align-items: start; justify-content: end; }
  &.align--end--center { align-items: center; justify-content: end; }
  &.align--end--end { align-items: end; justify-content: end; }
  &.align--end--fill { align-items: stretch; justify-content: end; }
  &.align--center--start { align-items: start; justify-content: center; }
  &.align--center--center { align-items: center; justify-content: center; }
  &.align--center--end { align-items: end; justify-content: center; }
  &.align--center--fill { align-items: stretch; justify-content: center; }
  &.align--fill--start { align-items: start; justify-content: space-between; }
  &.align--fill--center { align-items: center; justify-content: space-between; }
  &.align--fill--end { align-items: end; justify-content: space-between; }
  &.align--fill--fill { align-items: stretch; justify-content: space-between; }
}

.layout--horizontalWrap {
  flex-wrap: wrap;
}

.layout--vertical {
  display: flex;
  flex-direction: column;

  &.align--start--start { align-items: start; justify-content: start; }
  &.align--start--center { align-items: start; justify-content: center; }
  &.align--start--end { align-items: start; justify-content: end; }
  &.align--start--fill { align-items: start; justify-content: space-between; }
  &.align--end--start { align-items: end; justify-content: start; }
  &.align--end--center { align-items: end; justify-content: center; }
  &.align--end--end { align-items: end; justify-content: end; }
  &.align--end--fill { align-items: end; justify-content: space-between; }
  &.align--center--start { align-items: center; justify-content: start; }
  &.align--center--center { align-items: center; justify-content: center; }
  &.align--center--end { align-items: center; justify-content: end; }
  &.align--center--fill { align-items: center; justify-content: space-between; }
  &.align--fill--start { align-items: stretch; justify-content: start; }
  &.align--fill--center { align-items: stretch; justify-content: center; }
  &.align--fill--end { align-items: stretch; justify-content: end; }
  &.align--fill--fill { align-items: stretch; justify-content: space-between; }
}

.layout--fill {
  flex-grow: 1;
}


/**** Spacing ****/

.spacing--none { padding: 0; }

.spacing--small { padding: var(--dimensions--spacing--small); }
.spacing--small--block { padding-block: var(--dimensions--spacing--small); }
.spacing--small--inline { padding-inline: var(--dimensions--spacing--small); }
.spacing--small--blockStart { padding-block-start: var(--dimensions--spacing--small); }
.spacing--small--blockEnd { padding-block-end: var(--dimensions--spacing--small); }
.spacing--small--inlineStart { padding-inline-start: var(--dimensions--spacing--small); }
.spacing--small--inlineEnd { padding-inline-end: var(--dimensions--spacing--small); }

.spacing--medium { padding: var(--dimensions--spacing--medium); }
.spacing--medium--block { padding-block: var(--dimensions--spacing--medium); }
.spacing--medium--inline { padding-inline: var(--dimensions--spacing--medium); }
.spacing--medium--blockStart { padding-block-start: var(--dimensions--spacing--medium); }
.spacing--medium--blockEnd { padding-block-end: var(--dimensions--spacing--medium); }
.spacing--medium--inlineStart { padding-inline-start: var(--dimensions--spacing--medium); }
.spacing--medium--inlineEnd { padding-inline-end: var(--dimensions--spacing--medium); }

.spacing--large { padding: var(--dimensions--spacing--large); }
.spacing--large--block { padding-block: var(--dimensions--spacing--large); }
.spacing--large--inline { padding-inline: var(--dimensions--spacing--large); }
.spacing--large--blockStart { padding-block-start: var(--dimensions--spacing--large); }
.spacing--large--blockEnd { padding-block-end: var(--dimensions--spacing--large); }
.spacing--large--inlineStart { padding-inline-start: var(--dimensions--spacing--large); }
.spacing--large--inlineEnd { padding-inline-end: var(--dimensions--spacing--large); }

.gap--small { gap: var(--dimensions--spacing--small); }
.gap--medium { gap: var(--dimensions--spacing--medium); }
.gap--large { gap: var(--dimensions--spacing--large); }
.gap--small--row { row-gap: var(--dimensions--spacing--small); }
.gap--medium--row { row-gap: var(--dimensions--spacing--medium); }
.gap--large--row { row-gap: var(--dimensions--spacing--large); }
.gap--small--column { column-gap: var(--dimensions--spacing--small); }
.gap--medium--column { column-gap: var(--dimensions--spacing--medium); }
.gap--large--column { column-gap: var(--dimensions--spacing--large); }

/**** Scroll ****/

.scroll {
  -webkit-overflow-scrolling: touch;
}

.scroll--horizontal { overflow-x: auto; overflow-y: hidden; }
.scroll--vertical { overflow-y: auto; overflow-x: hidden; }

.scroll--horizontal.scroll--snapping { scroll-snap-type: x mandatory; }
.scroll--vertical.scroll--snapping { scroll-snap-type: y mandatory; }
.scroll--horizontal.scroll--snapping.scroll--proximity { scroll-snap-type: x proximity; }
.scroll--vertical.scroll--snapping.scroll--proximity { scroll-snap-type: y proximity; }

.scroll--align--start { scroll-snap-align: start; }
.scroll--align--center { scroll-snap-align: center; }
.scroll--align--end { scroll-snap-align: end; }
