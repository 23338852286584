.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: #333;
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  margin: 0 !important;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

:root {
  --color--primary: #ffeb33;
  --color--secondary: #0028d8;
  --color--secondary-lighter: #4c69e4;
  --color--success: #00b674;
  --color--warning: #ff5722;
  --color--danger: #e22e2e;
  --color--neutral-0: #000;
  --color--neutral-1: #222;
  --color--neutral-3: #464646;
  --color--neutral-5: #999;
  --color--neutral-6: #c4c4c4;
  --color--neutral-7: #d8d8d8;
  --color--neutral-8: #e5e5e5;
  --color--neutral-9: #fefefe;
  --color--neutral-10: #fff;
  --dimensions--borderRadius--small: .5rem;
  --dimensions--borderRadius--medium: 1rem;
  --dimensions--borderRadius--large: 2rem;
  --dimensions--spacing--small: clamp(.25rem, .25vw, .5rem);
  --dimensions--spacing--medium: clamp(1rem, 1vw, 1.5rem);
  --dimensions--spacing--large: clamp(2rem, 2vw, 3rem);
  --motion--duration--short: .15s;
  --motion--duration--medium: .3s;
  --motion--duration--long: .5s;
  --motion--easing--inOut: ease-in-out;
  --ata--app--backgroundColor: var(--color--neutral-9);
  --ata--app--foregroundColor: var(--color--neutral-0);
  --ata--app--foregroundColor-light: var(--color--neutral-5);
  --ata--app--link--foregroundColor: var(--color--secondary);
  --ata--app--link--foregroundColor--hover: var(--color--secondary-lighter);
  --ata--app--divider--color: var(--color--neutral-7);
  --ata--app--bar--backgroundColor: var(--color--primary);
  --ata--app--bar--foregroundColor: var(--color--neutral-0);
  --ata--app--bar--blockSize: 7rem;
  --ata--app--navigation--inlineSize: 44rem;
  --ata--app--navigation--backgroundColor: #e3f6f8;
  --ata--app--navigation--foregroundColor: #000;
  --ata--app--navigation--backgroundColor--active: #bde2e5;
  --ata--app--navigation--foregroundColor--active: #000;
  --ata--app--infoBlock--backgroundColor: var(--color--neutral-8);
  --ata--app--infoBlock--foregroundColor: var(--color--neutral-0);
  --ata--layout--gap--small: var(--dimensions--spacing--small);
  --ata--layout--gap--medium: var(--dimensions--spacing--medium);
  --ata--layout--gap--large: var(--dimensions--spacing--large);
  --ata--form--error--foregroundColor: var(--color--danger);
  --ata--form--section--borderColor: var(--color--neutral-7);
  --ata--form--section--gap: var(--dimensions--spacing--large);
  --ata--form--field--gap: var(--dimensions--spacing--medium);
  --ata--card--backgroundColor: var(--color--neutral-10);
  --ata--card--borderRadius: var(--dimensions--borderRadius--medium);
  --ata--card--borderColor: var(--color--neutral-7);
  --ata--card--boxShadow: none;
  --ata--table--cell--backgroundColor: var(--color--neutral-10);
  --ata--table--cell--foregroundColor: var(--color--neutral-1);
  --ata--table--cell--padding: var(--dimensions--spacing--medium);
  --ata--table--cell--borderColor: var(--color--neutral-7);
  --ata--table--cell--backgroundColor--alternate: var(--color--neutral-9);
  --ata--table--cell--foregroundColor--alternate: var(--color--neutral-1);
  --ata--table--header--backgroundColor: var(--color--neutral-8);
  --ata--table--header--foregroundColor: var(--ata--table--cell--foregroundColor);
  --ata--table--header--borderColor: var(--ata--table--header--backgroundColor);
  --ata--table--header--offset: 7rem auto auto auto;
  --ata--table--rowGap: var(--dimensions--spacing--small);
  --ata--button--borderRadius: var(--dimensions--borderRadius--small);
  --ata--button--blockSize: 5rem;
  --ata--button--blockSize--dense: 4rem;
  --ata--button--outlineColor: var(--color--neutral-7);
  --ata--button--color: var(--color--neutral-0);
  --ata--button--onColor: var(--color--neutral-10);
  --ata--button--color--disabled: var(--color--neutral-6);
  --ata--button--onColor--disabled: var(--color--neutral-5);
  --ata--button--color--primary: var(--color--secondary);
  --ata--button--onColor--primary: var(--color--neutral-10);
  --ata--button--color--secondary: var(--color--neutral-0);
  --ata--button--onColor--secondary: var(--color--neutral-10);
  --ata--button--color--success: var(--color--success);
  --ata--button--onColor--success: var(--color--neutral-0);
  --ata--button--color--warning: var(--color--warning);
  --ata--button--onColor--warning: var(--color--neutral-10);
  --ata--button--color--danger: var(--color--danger);
  --ata--button--onColor--danger: var(--color--neutral-10);
  --ata--input--blockSize: 4rem;
  --ata--input--backgroundColor: var(--color--neutral-10);
  --ata--input--foregroundColor: var(--color--neutral-0);
  --ata--input--borderColor: var(--color--neutral-7);
  --ata--input--borderRadius: var(--dimensions--borderRadius--small);
  --ata--selection--blockSize: var(--ata--input--blockSize);
  --ata--selection--backgroundColor: var(--ata--input--backgroundColor);
  --ata--selection--foregroundColor: var(--ata--input--foregroundColor);
  --ata--selection--borderColor: var(--ata--input--borderColor);
  --ata--selection--borderRadius: var(--ata--input--borderRadius);
  --ata--progressBar--blockSize: 2rem;
  --ata--vehiclePageHeader--backgroundColor: var(--color--neutral-8);
  --ata--vehiclePageHeader--borderColor: var(--color--neutral-7);
  --ata--chart--tooltip--backgroundColor: var(--color--neutral-1);
  --ata--chart--tooltip--foregroundColor: var(--color--neutral-10);
  --ata--chart--tooltip--dividerColor: var(--color--neutral-8);
  --ata--chart--tooltip--padding: var(--dimensions--spacing--medium);
  --ata--chart--tooltip--spacing: var(--dimensions--spacing--small);
  --ata--chart--tooltip--borderRadius: var(--dimensions--borderRadius--small);
  --ata--tooltip--backgroundColor: var(--color--neutral-1);
  --ata--tooltip--foregroundColor: var(--color--neutral-10);
  --ata--tooltip--borderRadius: var(--dimensions--borderRadius--small);
  --ata--tooltip--padding: var(--dimensions--spacing--small);
  --ata--vehicle--image--background: var(--color--neutral-7);
}

@media (prefers-reduced-motion) {
  ::view-transition-group(*) {
    animation: none !important;
  }

  ::view-transition-old(*) {
    animation: none !important;
  }

  ::view-transition-new(*) {
    animation: none !important;
  }
}

::view-transition-old(*) {
  mix-blend-mode: normal;
  animation: none;
}

::view-transition-new(*) {
  mix-blend-mode: normal;
  animation: none;
}

* {
  box-sizing: border-box;
  accent-color: var(--color--secondary);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

html {
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato, sans-serif;
  font-size: 8px;
}

body {
  background: var(--ata--app--backgroundColor);
  color: var(--ata--app--foregroundColor);
  font-size: 14px;
  container-type: inline-size;
}

html, body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  border-top: 1px solid var(--ata--app--divider--color);
  inline-size: 100%;
  margin: 0;
}

a[href] {
  color: var(--ata--app--link--foregroundColor);
  text-decoration: none;
}

a[href]:hover, a[href]:focus {
  color: var(--ata--app--link--foregroundColor--hover);
  text-decoration: underline;
}

details > summary {
  cursor: pointer;
  list-style: none;
}

details[open] > summary {
  margin-block-end: var(--dimensions--spacing--medium);
}

details > summary:after {
  content: "▼";
  display: inline-block;
}

details[open] > summary:after {
  content: "▲";
}

.google-visualization-tooltip {
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
  font-size: .85em !important;
}

.gm-style .gm-style-iw-c {
  background: var(--ata--tooltip--backgroundColor);
  color: var(--ata--tooltip--foregroundColor);
  border-radius: var(--ata--tooltip--borderRadius);
  padding: var(--ata--tooltip--padding);
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-style .gm-style-iw-tc:after {
  background: var(--ata--tooltip--backgroundColor);
}

.gm-style .gm-ui-hover-effect > span {
  background-color: var(--ata--tooltip--foregroundColor);
}

.form {
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 3rem 2rem;
  display: grid;
}

.form .form__field {
  display: contents;
}

.form .form__field .field__label {
  grid-column: 1;
}

.form .form__field .field__input {
  grid-column: 2;
}

.form .form__full {
  grid-column: 1 / -1;
}

.form[data-error="true"] {
  animation: .3s ease-in-out forwards shake;
}

.form .form__error {
  color: var(--ata--form--error--foregroundColor);
  font-size: .875em;
  line-height: 1.4em;
}

.form .form__error:empty {
  display: none;
}

.field:has(:user-invalid) .field__label {
  color: var(--color--danger);
}

.input {
  background: var(--ata--input--backgroundColor);
  color: var(--ata--input--foregroundColor);
  border: 1px solid var(--ata--input--borderColor);
  border-radius: var(--ata--input--borderRadius);
  height: 5rem;
  padding-inline: 1.5rem;
  font-size: 1em;
  font-weight: 400;
  line-height: 2.15em;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-.5rem);
  }

  50% {
    transform: translateX(.5rem);
  }

  75% {
    transform: translateX(-.5rem);
  }

  100% {
    transform: translateX(0);
  }
}

.card {
  outline: 1px solid var(--ata--card--borderColor);
  border-radius: var(--ata--card--borderRadius);
  overflow: hidden;
}

.card .card__section:not(:last-child) {
  border-block-end: 1px solid var(--ata--card--borderColor);
}

.table {
  border-collapse: separate;
  border-spacing: 0 .5rem;
  width: 100%;
  max-width: 100%;
  display: table;
}

.table__row {
  background: var(--ata--table--cell--backgroundColor);
  color: var(--ata--table--cell--foregroundColor);
  width: 100%;
  margin-bottom: 1rem;
  display: table-row;
}

.table__row > * {
  box-sizing: border-box;
  padding: var(--ata--table--cell--padding);
  border: 1px solid var(--ata--table--cell--borderColor);
  border-right: none;
  max-width: 100%;
  display: table-cell;
}

.table__row > :last-child {
  border-right: 1px solid var(--ata--table--cell--borderColor);
}

.table__row--header {
  background-color: var(--ata--table--header--backgroundColor);
  color: var(--ata--table--header--foregroundColor);
}

.table__row--header > * {
  text-align: start;
  border-color: var(--ata--table--header--borderColor);
  vertical-align: middle;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-weight: 600;
}

.table__row--header > * span.sorted {
  font-size: .8em;
}

.table__row--header > * span.sorted:before {
  content: "▼";
  display: inline-block;
}

.table__row--header > * span.sorted.sorted--reverse:before {
  content: "▲";
}

ata-text-button[theme="primary"], ata-stroked-button[theme="primary"], ata-filled-button[theme="primary"] {
  --ata--button--color: var(--ata--button--color--primary);
  --ata--button--onColor: var(--ata--button--onColor--primary);
  --ata--button--borderColor: var(--ata--button--color--primary);
}

ata-text-button[theme="secondary"], ata-stroked-button[theme="secondary"], ata-filled-button[theme="secondary"] {
  --ata--button--color: var(--ata--button--color--secondary);
  --ata--button--onColor: var(--ata--button--onColor--secondary);
  --ata--button--borderColor: var(--color--neutral-6);
}

ata-text-button[theme="success"], ata-stroked-button[theme="success"], ata-filled-button[theme="success"] {
  --ata--button--color: var(--ata--button--color--success);
  --ata--button--onColor: var(--ata--button--onColor--success);
  --ata--button--borderColor: var(--ata--button--color--success);
}

ata-text-button[theme="warning"], ata-stroked-button[theme="warning"], ata-filled-button[theme="warning"] {
  --ata--button--color: var(--ata--button--color--warning);
  --ata--button--onColor: var(--ata--button--onColor--warning);
  --ata--button--borderColor: var(--ata--button--color--warning);
}

ata-text-button[theme="danger"], ata-stroked-button[theme="danger"], ata-filled-button[theme="danger"] {
  --ata--button--color: var(--ata--button--color--danger);
  --ata--button--onColor: var(--ata--button--onColor--danger);
  --ata--button--borderColor: var(--ata--button--color--danger);
}

ata-text-button:disabled, ata-stroked-button:disabled, ata-filled-button:disabled {
  --ata--button--color: var(--ata--button--color--disabled);
  --ata--button--onColor: var(--ata--button--onColor--disabled);
  --ata--button--borderColor: var(--color--neutral-6);
}

ata-progress-bar[theme="Default"] {
  --ata--progressBar--backgroundColor: var(--color--neutral-7);
  --ata--progressBar--valueColor: var(--color--secondary);
}

ata-progress-bar[theme="Success"] {
  --ata--progressBar--backgroundColor: var(--color--neutral-7);
  --ata--progressBar--valueColor: var(--color--success);
}

ata-progress-bar[theme="Warning"] {
  --ata--progressBar--backgroundColor: var(--color--neutral-7);
  --ata--progressBar--valueColor: var(--color--warning);
}

ata-progress-bar[theme="Danger"] {
  --ata--progressBar--backgroundColor: var(--color--neutral-7);
  --ata--progressBar--valueColor: var(--color--danger);
}

.paragraph-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.paragraph-list > * {
  display: inline;
}

.paragraph-list.paragraph-list--bullets > :not(:last-child):after {
  content: " •";
  margin-inline: var(--dimensions--spacing--small);
  color: initial;
}

.contained {
  margin-inline: auto;
}

.contained--small {
  max-inline-size: 60rem;
}

.contained--medium {
  max-inline-size: 90rem;
}

.contained--large {
  max-inline-size: 120rem;
}

.infoBlock {
  background: var(--ata--app--infoBlock--backgroundColor);
  color: var(--ata--app--infoBlock--foregroundColor);
  fill: var(--ata--app--infoBlock--foregroundColor);
  padding: var(--dimensions--spacing--large);
  gap: var(--dimensions--spacing--large);
  flex-flow: wrap;
  display: flex;
}

.infoBlock[hidden] {
  display: none;
}

.infoBlock > * {
  flex: 80ch;
}

.infoBlock h1 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 2em;
}

.infoBlock h2 {
  margin-block-start: 1em;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.5em;
}

.infoBlock section {
  margin-block-end: var(--dimensions--spacing--medium);
}

.infoBlock p, .infoBlock ul, .infoBlock ol, .infoBlock dl {
  margin-block: 1rem 2rem;
  line-height: 1.6em;
}

.infoBlock ul, .infoBlock ol {
  margin-left: 0;
  padding-left: 2rem;
}

.infoBlock dl dt {
  margin-top: 2rem;
  font-weight: bold;
}

.infoBlock dl dd {
  margin-left: 0;
}

ata-input {
  display: contents;
}

ata-input > span {
  display: none;
}

ata-app-bar {
  background: var(--ata--app--bar--backgroundColor);
  color: var(--ata--app--bar--foregroundColor);
  fill: var(--ata--app--bar--foregroundColor);
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: ". logo action";
  align-items: center;
  height: 7rem;
  padding-inline: 1rem;
  display: grid;
}

ata-app-bar > * {
  height: 3rem;
  max-height: 100%;
}

ata-app-bar img {
  max-width: 100%;
  max-height: 100%;
}

ata-app-bar .appBar__logo {
  grid-area: logo;
}

ata-app-bar .appBar__action {
  grid-area: action;
  justify-self: end;
}

@media (min-width: 1200px) {
  ata-app-bar {
    grid-template-columns: auto 1fr;
    grid-template-areas: "logo action";
  }
}

ata-app-footer {
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid var(--ata--app--divider--color);
  color: var(--color--neutral-5);
  padding: 3rem 2rem;
  font-size: .8em;
  display: block;
}

ata-app-footer > :not(:last-child):after {
  content: "|";
  margin-inline: 1rem;
  display: inline-block;
}

ata-page-header {
  border-block-end: 1px solid var(--ata--app--divider--color);
}

debounce-input {
  display: contents;
}

main {
  container: main / inline-size;
}

.shell {
  transition: transform var(--motion--duration--long) var(--motion--easing--inOut);
}

.shell .shell__header {
  z-index: 1;
  block-size: var(--ata--app--bar--blockSize);
  position: sticky;
  top: 0;
}

.shell .shell__navigation {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
}

.shell:has(.shell__navigation[open]) {
  transform: translate(calc(var(--ata--app--navigation--inlineSize, 0rem) * -1));
}

@media (min-width: 1200px) {
  .shell:has(.shell__navigation) .shell__content {
    padding-left: var(--ata--app--navigation--inlineSize);
  }

  .shell .shell__navigation {
    top: var(--ata--app--bar--blockSize);
    left: 0;
  }

  .shell:has(.shell__navigation[open]) {
    transform: translate(0);
  }
}

.shell nav {
  width: var(--ata--app--navigation--inlineSize);
  background: var(--ata--app--navigation--backgroundColor);
  color: var(--ata--app--navigation--foregroundColor);
  fill: var(--ata--app--navigation--foregroundColor);
  height: 100%;
}

.shell nav ul {
  list-style: none;
}

.shell nav > ul > li {
  border-block-end: 1px solid var(--ata--app--divider--color);
  padding: 1rem 2rem;
}

.shell nav > ul > li ul {
  list-style: none;
}

.shell nav > ul > li ul li {
  padding: 1rem 0;
}

.shell nav > ul > li ul li:first-child {
  padding-top: 2rem;
}

.shell nav details > summary {
  list-style: none;
}

.navigation :is(a, a:hover, a:focus) {
  color: currentColor;
  text-decoration: none;
}

.navigation__item, .navigation__header {
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  padding-block: 1rem;
  display: flex;
}

.navigation__item .navigation__icon, .navigation__header .navigation__icon {
  aspect-ratio: 1;
  block-size: 2rem;
  display: inline-flex;
}

.navigation__item .navigation__label {
  text-transform: uppercase;
}

.navigation__header {
  gap: 2rem;
  padding: 3rem 2rem;
}

.navigation__header .navigation__icon {
  block-size: 3rem;
}

.navigation__sub-item {
  padding-inline-start: 5rem;
}

.typography--heading1 {
  font: 1.7em / 1.4em normal Lato, sans-serif;
}

.typography--heading2 {
  font: 1.3em / 1.4em normal Lato, sans-serif;
}

.typography--heading3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font: .85em / 1.4em bold Lato, sans-serif;
}

.typography--caption {
  font: .85em / 1em normal Lato, sans-serif;
}

.color--neutral {
  color: var(--color--neutral-0);
  fill: var(--color--neutral-0);
}

.color--neutral--light {
  color: var(--color--neutral-5);
  fill: var(--color--neutral-5);
}

.color--success {
  color: var(--color--success);
  fill: var(--color--success);
}

.color--warning {
  color: var(--color--warning);
  fill: var(--color--warning);
}

.color--danger {
  color: var(--color--danger);
  fill: var(--color--danger);
}

.layout--horizontal, .layout--horizontalWrap {
  flex-direction: row;
  display: flex;
}

.layout--horizontal.align--start--start, .layout--horizontalWrap.align--start--start {
  justify-content: start;
  align-items: start;
}

.layout--horizontal.align--start--center, .layout--horizontalWrap.align--start--center {
  justify-content: start;
  align-items: center;
}

.layout--horizontal.align--start--end, .layout--horizontalWrap.align--start--end {
  justify-content: start;
  align-items: end;
}

.layout--horizontal.align--start--fill, .layout--horizontalWrap.align--start--fill {
  justify-content: start;
  align-items: stretch;
}

.layout--horizontal.align--end--start, .layout--horizontalWrap.align--end--start {
  justify-content: end;
  align-items: start;
}

.layout--horizontal.align--end--center, .layout--horizontalWrap.align--end--center {
  justify-content: end;
  align-items: center;
}

.layout--horizontal.align--end--end, .layout--horizontalWrap.align--end--end {
  justify-content: end;
  align-items: end;
}

.layout--horizontal.align--end--fill, .layout--horizontalWrap.align--end--fill {
  justify-content: end;
  align-items: stretch;
}

.layout--horizontal.align--center--start, .layout--horizontalWrap.align--center--start {
  justify-content: center;
  align-items: start;
}

.layout--horizontal.align--center--center, .layout--horizontalWrap.align--center--center {
  justify-content: center;
  align-items: center;
}

.layout--horizontal.align--center--end, .layout--horizontalWrap.align--center--end {
  justify-content: center;
  align-items: end;
}

.layout--horizontal.align--center--fill, .layout--horizontalWrap.align--center--fill {
  justify-content: center;
  align-items: stretch;
}

.layout--horizontal.align--fill--start, .layout--horizontalWrap.align--fill--start {
  justify-content: space-between;
  align-items: start;
}

.layout--horizontal.align--fill--center, .layout--horizontalWrap.align--fill--center {
  justify-content: space-between;
  align-items: center;
}

.layout--horizontal.align--fill--end, .layout--horizontalWrap.align--fill--end {
  justify-content: space-between;
  align-items: end;
}

.layout--horizontal.align--fill--fill, .layout--horizontalWrap.align--fill--fill {
  justify-content: space-between;
  align-items: stretch;
}

.layout--horizontalWrap {
  flex-wrap: wrap;
}

.layout--vertical {
  flex-direction: column;
  display: flex;
}

.layout--vertical.align--start--start {
  justify-content: start;
  align-items: start;
}

.layout--vertical.align--start--center {
  justify-content: center;
  align-items: start;
}

.layout--vertical.align--start--end {
  justify-content: end;
  align-items: start;
}

.layout--vertical.align--start--fill {
  justify-content: space-between;
  align-items: start;
}

.layout--vertical.align--end--start {
  justify-content: start;
  align-items: end;
}

.layout--vertical.align--end--center {
  justify-content: center;
  align-items: end;
}

.layout--vertical.align--end--end {
  justify-content: end;
  align-items: end;
}

.layout--vertical.align--end--fill {
  justify-content: space-between;
  align-items: end;
}

.layout--vertical.align--center--start {
  justify-content: start;
  align-items: center;
}

.layout--vertical.align--center--center {
  justify-content: center;
  align-items: center;
}

.layout--vertical.align--center--end {
  justify-content: end;
  align-items: center;
}

.layout--vertical.align--center--fill {
  justify-content: space-between;
  align-items: center;
}

.layout--vertical.align--fill--start {
  justify-content: start;
  align-items: stretch;
}

.layout--vertical.align--fill--center {
  justify-content: center;
  align-items: stretch;
}

.layout--vertical.align--fill--end {
  justify-content: end;
  align-items: stretch;
}

.layout--vertical.align--fill--fill {
  justify-content: space-between;
  align-items: stretch;
}

.layout--fill {
  flex-grow: 1;
}

.spacing--none {
  padding: 0;
}

.spacing--small {
  padding: var(--dimensions--spacing--small);
}

.spacing--small--block {
  padding-block: var(--dimensions--spacing--small);
}

.spacing--small--inline {
  padding-inline: var(--dimensions--spacing--small);
}

.spacing--small--blockStart {
  padding-block-start: var(--dimensions--spacing--small);
}

.spacing--small--blockEnd {
  padding-block-end: var(--dimensions--spacing--small);
}

.spacing--small--inlineStart {
  padding-inline-start: var(--dimensions--spacing--small);
}

.spacing--small--inlineEnd {
  padding-inline-end: var(--dimensions--spacing--small);
}

.spacing--medium {
  padding: var(--dimensions--spacing--medium);
}

.spacing--medium--block {
  padding-block: var(--dimensions--spacing--medium);
}

.spacing--medium--inline {
  padding-inline: var(--dimensions--spacing--medium);
}

.spacing--medium--blockStart {
  padding-block-start: var(--dimensions--spacing--medium);
}

.spacing--medium--blockEnd {
  padding-block-end: var(--dimensions--spacing--medium);
}

.spacing--medium--inlineStart {
  padding-inline-start: var(--dimensions--spacing--medium);
}

.spacing--medium--inlineEnd {
  padding-inline-end: var(--dimensions--spacing--medium);
}

.spacing--large {
  padding: var(--dimensions--spacing--large);
}

.spacing--large--block {
  padding-block: var(--dimensions--spacing--large);
}

.spacing--large--inline {
  padding-inline: var(--dimensions--spacing--large);
}

.spacing--large--blockStart {
  padding-block-start: var(--dimensions--spacing--large);
}

.spacing--large--blockEnd {
  padding-block-end: var(--dimensions--spacing--large);
}

.spacing--large--inlineStart {
  padding-inline-start: var(--dimensions--spacing--large);
}

.spacing--large--inlineEnd {
  padding-inline-end: var(--dimensions--spacing--large);
}

.gap--small {
  gap: var(--dimensions--spacing--small);
}

.gap--medium {
  gap: var(--dimensions--spacing--medium);
}

.gap--large {
  gap: var(--dimensions--spacing--large);
}

.gap--small--row {
  row-gap: var(--dimensions--spacing--small);
}

.gap--medium--row {
  row-gap: var(--dimensions--spacing--medium);
}

.gap--large--row {
  row-gap: var(--dimensions--spacing--large);
}

.gap--small--column {
  column-gap: var(--dimensions--spacing--small);
}

.gap--medium--column {
  column-gap: var(--dimensions--spacing--medium);
}

.gap--large--column {
  column-gap: var(--dimensions--spacing--large);
}

.scroll {
  -webkit-overflow-scrolling: touch;
}

.scroll--horizontal {
  overflow: auto hidden;
}

.scroll--vertical {
  overflow: hidden auto;
}

.scroll--horizontal.scroll--snapping {
  scroll-snap-type: x mandatory;
}

.scroll--vertical.scroll--snapping {
  scroll-snap-type: y mandatory;
}

.scroll--horizontal.scroll--snapping.scroll--proximity {
  scroll-snap-type: x proximity;
}

.scroll--vertical.scroll--snapping.scroll--proximity {
  scroll-snap-type: y proximity;
}

.scroll--align--start {
  scroll-snap-align: start;
}

.scroll--align--center {
  scroll-snap-align: center;
}

.scroll--align--end {
  scroll-snap-align: end;
}

/*# sourceMappingURL=index.4b0c235c.css.map */
